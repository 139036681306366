import Router from 'next/router'


interface Props {
  children?: any,
  sitemap: any
}

export const Collapse: React.FC<Props> = (props) => {

  return (
    <>
      {
        props.sitemap.map((menu:any) => {
          return <div onClick={()=>{
            if (!menu.content.categories){
              {/*@ts-ignore */}
              window.gtag('event',`mobile_click_${(menu.content.link).replace('/','')}`)
              void Router.push(menu.content.link!)
            }
          }} key={`fc__${menu.text.substring(0, 20).toLowerCase().replaceAll(' ', '-')}`}
                      className={`show collapse rounded-box border-base-500 my-1 ${menu.content.categories?'collapse-arrow':''}`}>
            <input type="checkbox" className={`self-center `} style={{ minHeight: '2.8rem', paddingTop: '0px', paddingBottom: '0px',color:'blue' }} />
            <div className="collapse-title text-white text-xl font-medium flex"
                 style={{ minHeight: 'unset', paddingTop: '0px', paddingBottom: '0px' }}>
              <div className="self-end">
                {menu.text}
              </div>
            </div>
            {menu.content.categories &&
              <div className="collapse-content">
                {
                  menu.content.categories.map((linkCategory:any) => {
                    return linkCategory.linkGroups.map((linkGroup:any) => {
                      return <>
                        <div className="ml-2 mt-2 text-white text-md font-medium">
                          {linkGroup.link ? <a onClick={()=>{{/*@ts-ignore */}
                                            window.gtag('event',`mobile_click_${(linkGroup.header).toLowerCase().replace(' ','_')}`)}} href={linkGroup.link}>{linkGroup.header}</a> : linkGroup.header}
                          <div style={{ height: '12px' }}></div>
                          {linkGroup.links &&
                            linkGroup.links.map((link:any) => {
                              return <div key={`fc${link.title.substring(0, 20).toLowerCase().replaceAll(' ', '-')}`}
                                          className="text-sm pb-2 hover:text-white" style={{ color: '#C0C0C0' }}>
                                <a onClick={()=>{{/*@ts-ignore */}
                                            window.gtag('event',`mobile_click_${(link.title).toLowerCase().replace(' ','_')}`)}} href={link.url}
                                   key={`fca${link.title.substring(0, 20).toLocaleLowerCase().replaceAll(' ', '-')}`}>{link.title}</a>
                              </div>
                            })
                          }
                        </div>
                      </>
                    })
                  })
                }
              </div>
            }
          </div>
        })
      }
    </>
  )

}
