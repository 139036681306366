import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getRemoteConfig, getValue } from '@firebase/remote-config'
import { SocialMediaLinks } from '@src/pages/_app'
import { fetchAndActivate } from 'firebase/remote-config'

export function initAnalytics(firebaseConf: string, setLink: (links: SocialMediaLinks) => void) {
  const firebaseConfig = JSON.parse(firebaseConf)
  const app = initializeApp(firebaseConfig)
  getAnalytics(app)
  const remoteConfig = getRemoteConfig(app)

  remoteConfig.defaultConfig = {
    instagram_link: 'https://www.instagram.com/colendi_app/',
    linkedIn_link: 'https://www.linkedin.com/company/colendi/',
    twitter_link: 'https://twitter.com/colendiapp',
    facebook_link: 'https://www.facebook.com/colenditoken',
    telegram_link: 'https://t.me/colendi',
    youtube_link: 'https://www.youtube.com/@Colendi',
  }
  remoteConfig.settings.minimumFetchIntervalMillis = 60000

  fetchAndActivate(remoteConfig)
    .then(() => {
      setLink({
        instagramLink: getValue(remoteConfig, 'instagram_link').asString(),
        linkedInLink: getValue(remoteConfig, 'linkedIn_link').asString(),
        twitterLink: getValue(remoteConfig, 'twitter_link').asString(),
        facebookLink: getValue(remoteConfig, 'facebook_link').asString(),
        telegramLink: getValue(remoteConfig, 'telegram_link').asString(),
        youtubeLink: getValue(remoteConfig, 'youtube_link').asString(),
      })
    })
    .catch((err) => {
      console.log(err)
    })
}
