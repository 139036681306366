export interface InputState {
  value: string
  hasError: boolean
  showError: boolean
  errorValue:string
}


export const EmptyInputState:InputState = {
  value: '',
  hasError: false,
  showError: false,
  errorValue:''
}