import React, { useEffect, useRef, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { MenuHeader } from '@src/components/menuheader'
import AccordionMenu from '@src/components/accordionMenu'
import Router, { useRouter } from 'next/router'
import useSitemap from '@src/sitemap'
import styles from '../styles/navbar.module.scss'

const Navbar: React.FC<any> = () => {

  const router = useRouter()
  const isOtherPage = router.pathname.startsWith('/pages')

  const sitemapData = useSitemap()

  const goToHome = async () => {
    await Router.push('/')
  }

  const [openNavbar, setOpenNavbar] = useState<boolean>(false)

  useEffect(() => {
    console.log(`menu open: ${openNavbar}`)
    if (openNavbar) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [openNavbar])

  // @ts-ignore
  const disclosureRef = useRef<Disclosure | undefined>(undefined)

  return (

    <Disclosure ref={disclosureRef} as="nav" style={{ backgroundColor: '#F6F6F6E0' }} className="z-[111] fixed w-full">

      {isOtherPage ? <div className='h-[60px] flex justify-center items-center'>
        <img className={styles.navbarLogoOtherPages}
             src="/colendi_logo_black.svg" alt="Icon 3" />
      </div> : ({ open }) => (
        <>
          {
            setOpenNavbar(open)
          }
          <div className="w-full" style={{ backdropFilter: 'blur(2px)' }}>
            <div className={styles.topBar}>
              <div className="absolute inset-y-0 right-2 flex items-center lg:hidden">
                <Disclosure.Button className={styles.miniMenu}>
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div role="button" onClick={goToHome}>
                <img className="block lg:hidden h-5 w-auto ml-auto left-0"
                     src="/colendi_logo_black.svg" alt="Icon 1" />
              </div>
              <div className={styles.logoContainer}>
                <div className="flex-shrink-0 flex items-center">
                  <div role="button" onClick={goToHome}>
                    <img className={styles.navbarLogoFullScreen}
                         src="/colendi_logo_black.svg" alt="Icon 3" />
                  </div>
                </div>
                <div className="hidden lg:block sm:ml-6">
                  <div className="flex space-x-4">
                    {sitemapData && sitemapData.menus.map((menuButton) => {
                      if (menuButton.isOnRight) {
                        return <div />
                      }
                      return <MenuHeader key={`n-${menuButton.text.toLowerCase().replaceAll(' ', '-').substring(0, 20)}`}
                                         content={menuButton.content} text={menuButton.text} isOnRight={false} />
                    })}
                  </div>
                </div>
              </div>
              <div className="flex space-x-4 hidden lg:block">
                {sitemapData && sitemapData.menus.map((menuButton) => {
                  if (!menuButton.isOnRight) {
                    return <div />
                  }
                  return <MenuHeader key={`n-${menuButton.text.toLowerCase().replaceAll(' ', '-').substring(0, 20)}`}
                                     content={menuButton.content} text={menuButton.text} isOnRight />
                })}
              </div>
              {/* {links !== undefined && */}
              {/*  <div className="grid grid-cols-4 gap-x-2 gap-y-4 pt-1 items-center hidden md:flex"> */}
              {/*    <div role="button" onClick={() => { */}
              {/*      goTo(links.instagramLink) */}
              {/*    }} className={styles.navbarIconContainer}> */}
              {/*      <img className={styles.navbarIcon} src="/instagram-icon-black.png" alt="instagram" /> */}
              {/*      <img className={styles.navbarIconHover} src="/instagram-icon-hovered.png" alt="instagram" /> */}
              {/*    </div> */}
              {/*    <div role="button" onClick={() => { */}
              {/*      goTo(links.linkedInLink) */}
              {/*    }} className={styles.navbarIconContainer}> */}
              {/*      <img className={styles.navbarIcon} src="/linkedin-icon-black.png" alt="linkedin" /> */}
              {/*      <img className={styles.navbarIconHover} src="/linkedin-icon-hovered.png" alt="linkedin" /> */}
              {/*    </div> */}
              {/*    <div role="button" onClick={() => { */}
              {/*      goTo(links.twitterLink) */}
              {/*    }} className={styles.navbarIconContainer}> */}
              {/*      <img className={styles.navbarIcon} src="/twitter-icon-black.png" alt="twitter" /> */}
              {/*      <img className={styles.navbarIconHover} src="/twitter-icon-hovered.png" alt="twitter" /> */}
              {/*    </div> */}
              {/*    <div role="button" onClick={() => { */}
              {/*      goTo(links.facebookLink) */}
              {/*    }} className={styles.navbarIconContainer}> */}
              {/*      <img className={styles.navbarIcon} src="/facebook-icon-black.png" alt="facebook" /> */}
              {/*      <img className={styles.navbarIconHover} src="/facebook-icon-hovered.png" alt="facebook" /> */}
              {/*    </div> */}
              {/*    <div role="button" onClick={() => { */}
              {/*      goTo(links.telegramLink) */}
              {/*    }} className={styles.navbarIconContainer}> */}
              {/*      <img className={styles.navbarIcon} src="/telegram-icon-black.png" alt="telegram" /> */}
              {/*      <img className={styles.navbarIconHover} src="/telegram-icon-hovered.png" alt="telegram" /> */}
              {/*    </div> */}
              {/*  </div> */}
              {/* } */}
            </div>
          </div>
          <Disclosure.Panel className="md:hidden bg-white">
            <div className="px-2 pt-2 pb-2 fixed bg-white w-full h-full">
              {sitemapData && <AccordionMenu closeNavbar={() => setOpenNavbar(false)} menus={sitemapData.menus} />}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>

  )
}

export default Navbar
