import 'intl-tel-input/build/css/intlTelInput.css'
import '@src/styles/countrycodepicker.css'
import '@src/styles/tailwind.scss'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { AppProps } from 'next/app'
import HomepageFooter from '@src/components/homepageFooter'
import axios from 'axios'
import NavBar from '@src/components/navbar'
import { XIcon } from '@heroicons/react/outline'
import { Router } from 'next/router'
import { EmptyInputState, InputState } from '@src/utils/inputstate'
import { Dialog } from '@headlessui/react'
import TextField from '@mui/material/TextField'
import { initAnalytics } from '@src/firebase'
import styles from '@src/styles/footer.module.scss'
import testStyles from '@src/styles/test.module.scss'
import useTranslation from '@src/hooks/i18n'
import AppContext, { AppContextInterface } from '../../AppContext'
import { GTMPageView } from '../utils/gtm'
import useSitemap from '@src/sitemap'
import Script from 'next/script'
import { useRouter } from 'next/router'


interface ModalProps {
  buttonText: string
}

export interface SocialMediaLinks {
  instagramLink: string
  linkedInLink: string
  twitterLink: string
  facebookLink: string
  telegramLink: string
  youtubeLink: string
}

interface CookieData {
  title: string
  text: string
  checked: boolean
  onCheckChanged: (val: boolean) => void
}

const Cookie: React.FC<CookieData> = ({ title, text, checked, onCheckChanged }) => {
  function onChange() {
    onCheckChanged(!checked)
  }

  return (
    <div className="flex my-4">
      <div className="flex flex-col">
        <p style={{
          fontSize: '15px',
          color: '#333333',
          fontStyle: 'bold',
          fontWeight: '700'
        }}>{title}</p>
        <p className="pt-2" style={{
          fontSize: '14px',
          color: '#333333',
          fontStyle: 'normal',
          fontWeight: '400'
        }}>
          {text}
        </p>
      </div>
      <div className="form-control mt-1">
        <input id={title.toLowerCase().replaceAll(' ', '')} key={title.toLowerCase().replaceAll(' ', '')} type="checkbox"
               className="toggle toggle-accent" onChange={() => onChange()} checked={checked} />
      </div>
    </div>
  )
}

export function CookiesModal() {
  const translator = useTranslation().cookies
  const [checkedCookies, setCheckedCookies] = useState([false, false, false, false])
  const [isOpen, setIsOpen] = useState(false)

  const [showCookies, setShowCookies] = useState(false)

  useEffect(() => {
    let allCookiesAccepted = true
    const val = localStorage.getItem('cookie-1')
    if (val === null || val === '0') {
      allCookiesAccepted = false
    }

    setCheckedCookies([...checkedCookies])
    setIsOpen(!allCookiesAccepted)
  }, [])

  function closeModal() {
    setIsOpen(false)
  }

  function saveCookieStateToStorage(index: number, state: boolean) {
    localStorage.setItem(`cookie${index}`, state ? '1' : '0')
  }

  function changeCookieChecked(val: boolean, index: number) {
    const newCookiesState = checkedCookies
    newCookiesState[index] = val
    setCheckedCookies([...newCookiesState])
  }

  function acceptAllCookies() {
    saveCookieStateToStorage(-1, true)
    for (let i = 0; i < checkedCookies.length; i++) {
      checkedCookies[i] = true
      saveCookieStateToStorage(i, true)
    }
    setCheckedCookies([...checkedCookies])
    setIsOpen(false)
  }

  function acceptSelectedCookies() {
    saveCookieStateToStorage(-1, true)
    for (let i = 0; i < checkedCookies.length; i++) {
      saveCookieStateToStorage(i, checkedCookies[i])
    }
    setCheckedCookies([...checkedCookies])
    setIsOpen(false)
  }

  const cookies = [
    <Cookie
      text={translator.cookie1.text}
      title={translator.cookie1.title}
      checked onCheckChanged={(val: boolean) => {
    }} />,
    <Cookie
      text={translator.cookie2.text}
      title={translator.cookie2.title}
      checked={checkedCookies[0]} onCheckChanged={(val: boolean) => {
      changeCookieChecked(val, 0)
    }}
    />,
    <Cookie
      text={translator.cookie3.text}
      title={translator.cookie3.title}
      checked={checkedCookies[1]} onCheckChanged={(val: boolean) => {
      changeCookieChecked(val, 1)
    }}
    />,
    <Cookie
      text={translator.cookie4.text}
      title={translator.cookie4.title}
      checked={checkedCookies[2]} onCheckChanged={(val: boolean) => {
      changeCookieChecked(val, 2)
    }}
    />,
    <Cookie
      text={translator.cookie5.text}
      title={translator.cookie5.title}
      checked={checkedCookies[3]} onCheckChanged={(val: boolean) => {
      changeCookieChecked(val, 3)
    }}
    />
  ]

  return (
    <>
      {isOpen &&
        <Dialog
          className={testStyles.popupCookies}
          style={{ backgroundColor: '#454545ee' }}
          open={isOpen}
          onClose={(_: boolean) => closeModal()}
        >
          <div className="flex flex-col justify-end">
            <Dialog.Overlay />
            <div className="mb-4 mx-4 md:mb-8 md:mx-8 align-middle">
              <div className={styles.cookiesModalContent}>
                <div className="flex flex-col overflow-y-scroll">
                  <div className="flex items-end">
                    <div>

                      <span style={{
                        fontSize: '14px',
                        color: '#333333',
                        fontStyle: 'normal',
                        fontWeight: '400'
                      }}
                            dangerouslySetInnerHTML={{ __html: translator.reason.start }} />
                      <span role="button" style={{
                        fontSize: '14px',
                        color: '#333333',
                        textDecoration: 'underline',
                        fontWeight: '400'
                      }} dangerouslySetInnerHTML={{ __html: translator.reason.link }}
                            onClick={() => {
                              window.location.href = 'https://cms.colendilabs.com/uploads/Cookie_Policy_4c2b1f31fe.pdf'
                            }} />
                    </div>
                  </div>
                  <div className={styles.cookiesHolder}>
                    {showCookies &&
                      cookies.map((cookie) => {
                        return cookie
                      })
                    }
                  </div>
                </div>
                <div className="flex justify-between">
                  {!showCookies &&
                    <button style={{ placeSelf: 'start', marginTop: '1.5rem' }}
                            type="button"
                            onClick={acceptAllCookies}
                            className={`${testStyles.btnSubscribe}`}>
                      {translator.acceptAll}
                    </button>
                  }
                  {showCookies &&
                    <button style={{ placeSelf: 'start', marginTop: '1.5rem' }}
                            type="button"
                            onClick={acceptSelectedCookies}
                            className={`${testStyles.btnSubscribe}`}>
                      {translator.acceptSelected}
                    </button>
                  }

                  <div className="self-center" style={{ marginTop: '1.5rem' }}>
                    <span role="button" style={{
                      placeSelf: 'start',
                      fontSize: '14px',
                      color: '#333333',
                      textDecoration: 'underline',
                      fontWeight: '400'
                    }} dangerouslySetInnerHTML={{ __html: showCookies ? translator.close : translator.open }}
                          onClick={() => {
                            setShowCookies(!showCookies)
                          }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      }
    </>
  )
}

export function SubscribeModal(props: ModalProps) {
  const commonTranslator = useTranslation().common

  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [subscribedModelState, setSubscribedModelState] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<InputState>(EmptyInputState)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    {/*@ts-ignore */}
    window.gtag('event', 'web_view_subscirbe')
    setIsOpen(true)
  }

  const validateEmail = (email: string): boolean => {
    const regex = /\S+@\S+\.\S+/
    return !regex.test(email)
  }

  const onInputBlur = () => {
    setInputValue({
      ...inputValue,
      showError: true
    })
  }

  const onEmailChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const email: string = e.target.value
    const isValidated = validateEmail(email)
    setInputValue({
      ...inputValue,
      value: email,
      hasError: isValidated,
      errorValue: commonTranslator.invalidEmail
    })
  }

  const onSubmit = () => {
    {/*@ts-ignore */}
    window.gtag('event', `web_click_subscribe_area`)
    if (subscribedModelState) {
      // Already subscribed
      closeModal()
      return
    }

    if (isLoading) {
      return
    }

    if (!inputValue.hasError) {
      setIsLoading(true)
      const awshost = process.env.NEXT_PUBLIC_AWS_HOST
      axios.post(`${awshost}/subscribe`, {
        email: inputValue.value
      }).then(function(response) {
        setIsLoading(false)
        if (response.data.success && response.data.success === true) {
          {/*@ts-ignore */}
          window.gtag('event', 'web_complete_subscribe', {
            email: inputValue.value
          })
          setSubscribedModelState(true)
        } else {
          setInputValue({
            ...inputValue,
            hasError: true,
            errorValue: response.data.message
          })
        }
      }).catch(function(error) {
        setIsLoading(false)
        console.log(error)
      })
    }
  }

  const images: HTMLImageElement[] = []

  interface Paths {
    paths: string[]
  }

  function preload({ paths }: Paths) {
    for (let i = 0; i < paths.length; i += 1) {
      images[i] = new Image()
      images[i].src = paths[i]
    }
  }

  useEffect(() => {


    preload({
        paths: [
          '/heroBlank.png',
          '/colendi_horizontal_dark.png',
          '/facebook-icon-hovered.png',
          '/instagram-icon-hovered.png',
          '/linkedin-icon-hovered.png',
          '/telegram-icon-hovered.png',
          '/twitter-icon-hovered.png']
      }
    )
  }, [])

  return (
    <>
      <button type="button" className={testStyles.btnSubscribe}
              onClick={() => {
                {/*@ts-ignore */}
                window.gtag('event', `web_click_subscribe`)
                openModal()
              }}>
        {props.buttonText}
      </button>

      {isOpen &&
        <Dialog
          // as="div"
          className="fixed z-10 inset-0 overflow-y-auto px-4"
          style={{ backgroundColor: '#454545ee' }}
          open={isOpen}
          onClose={closeModal}
        >
          <div className="flex justify-center">
            <Dialog.Overlay />

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
            </span>
            <div className="inline-block w-full max-w-2xl p-6 self-center my-8 align-middle bg-white shadow-xl rounded-2xl">

              <XIcon onClick={closeModal} id="closesubscribe" className={styles.tryModalClose} />
              <div className={styles.subscribeModalContent}>
                <img className="w-1/2 md:w-1/4" src="/colendi_horizontal_dark.png" alt="subscribe" />
                <div className="flex items-end pt-8">
                  {subscribedModelState ?
                    <>
                      <p style={{
                        fontSize: '22px',
                        color: '#333333',
                        fontStyle: 'normal',
                        fontWeight: '500'
                      }}>{commonTranslator.subscribed}</p>
                      <img style={{ width: '40px', height: '40px', marginLeft: '10px' }} src="/subscribed.png"
                           alt="subscribe" />
                    </>
                    :
                    <p style={{
                      fontSize: '22px',
                      color: '#333333',
                      fontStyle: 'normal',
                      fontWeight: '500'
                    }}>{subscribedModelState ? commonTranslator.subscribed : commonTranslator.subscribeColendi}</p>
                  }
                </div>
                <div className="flex items-end pt-1">
                  {!subscribedModelState &&
                    <p style={{
                      fontSize: '20px',
                      color: '#333333',
                      paddingTop: '0.5rem',
                      fontStyle: 'normal',
                      fontWeight: '300',
                      textAlign: 'left'
                    }}>{commonTranslator.enterMail}</p>
                  }
                </div>
                {!subscribedModelState &&
                  <div className="flex flex-row pt-4">
                    <TextField onBlur={onInputBlur} onChange={onEmailChange}
                               disabled={isLoading}
                               error={inputValue.showError && inputValue.hasError}
                               helperText={inputValue.showError && inputValue.hasError ? inputValue.errorValue : ''}
                               fullWidth
                               id="email" label={commonTranslator.email}
                               variant="outlined" />
                  </div>
                }
                <div className="flex items-end pt-1">
                  {subscribedModelState &&
                    <p style={{
                      marginTop: '3px',
                      fontSize: '20px',
                      color: '#333333',
                      fontStyle: 'normal',
                      fontWeight: '300',
                      textAlign: 'left'
                    }}></p>
                  }
                </div>
                <button style={{ placeSelf: 'start', marginTop: '1.5rem' }} type="button"
                        onClick={onSubmit}
                        className={`${testStyles.btnSubscribe}`}>
                  {subscribedModelState ? commonTranslator.close : commonTranslator.subscribe}
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      }
    </>
  )
}

//fullStandalonePages has no header or footer
const fullStandalonePages = ['/pages/hazirlimit/']

const App = (
  {
    Component, pageProps
  }
    : AppProps) => {

  const [linkData, setLinks] = useState<SocialMediaLinks | undefined>(undefined)

  const router = useRouter()
  const isOtherPage = router.pathname.startsWith('/pages')
  const isStandalonePage = fullStandalonePages.find(p => router.pathname.startsWith(p)) != undefined

  // const footerContext = useContext(FooterContext)
  // const footerRef = useRef() as React.MutableRefObject<HTMLDivElement>
  // const isVisible = useOnScreen(footerRef)
  // useEffect(() => {
  //   console.log(`visible ${isVisible}`)
  //   footerContext.isVisible = isVisible
  // }, [isVisible])


  const [isFooterInViewport, setIsFooterInViewport] = useState(false)

  const sitemapData = useSitemap().menus

  Router.events.on('routeChangeStart', () => {
    let search = window.location.href
    sitemapData.map((menu) => {
      menu.content.categories?.map((linkCategory) => {
        linkCategory.linkGroups.map((linkGroup) => {
          if (`/${search.split('/').pop()?.split('?')[0]}` === linkGroup.link) {
            {/*@ts-ignore */}
            window.gtag('event', `web_view_${linkGroup.header.toLowerCase().replaceAll(' ', '-')}`)
          }
          if (`/${search.split('/').pop()}` === linkGroup.link) {
            {/*@ts-ignore */}
            window.gtag('event', `web_view_${linkGroup.header.toLowerCase().replaceAll(' ', '-')}`)
          }
          linkGroup.links?.map((link) => {
            if (`/${search.split('/').pop()}` === link.url) {
              {/*@ts-ignore */}
              window.gtag('event', `web_view_${link.title.toLowerCase().replaceAll(' ', '-')}`)
            }
          })
        })
      })
    })
  })

  useEffect(() => {
    initAnalytics(process.env.NEXT_PUBLIC_FIREBASE_CONFIG as string, setLinks)
    void axios.get('/sitemap.json').then((response) => {
      const { data } = response
      setLinks(linkData)
    })
  }, [])

  useEffect(() => {
    const handleRouteChange = (url: string) => GTMPageView(url)
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  // Keeps track of open menu panel
  const [navbarMenuId, setNavbarMenuId] = useState<string>('')

  const initialAppContext: AppContextInterface = useMemo(() => {
    return { navbarMenuId, setNavbarMenuId }
  }, [{ navbarMenuId, setNavbarMenuId }])

  return <>

    <AppContext.Provider value={initialAppContext}>
      {!isStandalonePage &&
        <div>
          <NavBar />
        </div>
      }
    </AppContext.Provider>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Script strategy="afterInteractive" async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTAG_MEASUEREMENT_ID as string}`} />

    <Script id="google-analytics" strategy="afterInteractive">
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${process.env.NEXT_PUBLIC_GTAG_MEASUEREMENT_ID as string}');
      `}
    </Script>
    <Component {...pageProps} isFooterInViewport={isFooterInViewport} />

    {!isOtherPage &&
      <>
        <HomepageFooter setFooterIsInViewport={(val: boolean) => setIsFooterInViewport(val)}
                        links={linkData} />
      </>
    }
  </>
}

export default App
