import { Link as LinkModal, LinkCategory, LinkGroup, MenuButton, SitemapData } from '@src/interfaces/sitemap'
import React, { useState } from 'react'
import styles from '@src/styles/test.module.scss'

const AccordionMenu: React.FC<SitemapData> = ({ menus }) => {
  const [selectedMenuHeader, setSelectedMenuHeader] = useState<string>('')


  const selectMenu = (id: string) => {
    setSelectedMenuHeader(id)
  }

  const isSelected = (id: string, index: number): boolean => {
    if (selectedMenuHeader === '' && index === 0) {
      return true
    }
    return selectedMenuHeader === id
  }

  function goTo(e: any, link: string) {
    if (link !== '') {
      e.preventDefault()
      e.stopPropagation()
      window.location.href = link
    }
    return false
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="flex flex-row w-full justify-around">
          {
            menus.map((menu: MenuButton, index: number) => {
              return (
                <div
                  key={`accord-${menu.text.toLowerCase().replaceAll(' ', '-')}`}
                  className={
                    isSelected(menu.content.id, index) ? styles.navbarMobileMenuHeaderSelected : styles.navbarMobileMenuHeader
                  }
                  role="button"
                  onClick={(e) => {
                    if (menu.content.categories) {
                      selectMenu(menu.content.id)
                    } else {
                      {/* @ts-ignore */ }
                      window.gtag('event', `mobile_click_${(menu.content.link).replace('/', '')}`)
                      goTo(e, menu.content.link!)
                    }
                  }}
                >
                  {menu.text}
                </div>
              )
            })
          }
        </div>
      </div>
      <>
        {
          menus.map((menu: MenuButton, index: number) => {
            return (
              <>
                {
                  menu.content.categories &&
                  <div className={(isSelected(menu.content.id, index)) ? 'h-full flex flex-col overflow-y-scroll' : 'hidden'}>
                    {
                      menu.content.categories.map((category: LinkCategory) => {
                        return category.linkGroups.map((linkGroup: LinkGroup) => {
                          let identifier = linkGroup.header
                          if (linkGroup.header === undefined) {
                            identifier = linkGroup.id
                          }
                          return <div className="w-full rounded-box flex flex-col pt-8"
                            key={identifier.toLowerCase().replaceAll(' ', '-').substring(0, 20)}
                          >
                            <div className={styles.menuHeaderLink}>
                              {linkGroup.link ?
                                <span role="button" onClick={(e) => {
                                  console.log(linkGroup)
                                  {/* @ts-ignore */ }
                                  window.gtag('event', `mobile_click_${(linkGroup.header).toLowerCase().replace(' ', '_')}`); goTo(e, linkGroup.link ?? '')
                                }}>{linkGroup.header}</span>
                                :
                                linkGroup.header
                              }
                            </div>

                            {linkGroup.links &&
                              linkGroup.links.map((link: LinkModal) => {
                                return <div className={styles.mobilemenuLink}>
                                  <span role="button" onClick={(e) => {
                                    {/* @ts-ignore */ }
                                    window.gtag('event', `mobile_click_${(link.title).toLowerCase().replace(' ', '_')}`); goTo(e, link.url)
                                  }}>{link.title}</span>
                                </div>
                              })
                            }
                          </div>
                        })
                      })
                    }
                  </div>
                }
              </>
            )
          })
        }
      </>
    </>
  )
}

export default AccordionMenu