import useTranslation from "@src/hooks/i18n"

export default function useSitemap() {

  const translator = useTranslation().sitemap

  return {
    menus: [
      {
        text: translator.personal,
        url: "/personal",
        content: {
          id: "personal menu",
          link: "/personal",
          header: "",
          categories: [
            {
              groupId: "personal1",
              linkGroups: [
                {
                  header: translator.justInTimeFinance,
                  link: "/personal",
                  links: [
                    {
                      title: translator.alternativeScoring,
                      url: "/personal?p=1"
                    },
                    {
                      title: translator.buyNowPayLater,
                      url: "/personal?p=2"
                    },
                    {
                      title: translator.instantPaymentOptions,
                      url: "/personal?p=3"
                    }
                  ]
                },
                {
                  header: translator.invest,
                  link: "/personal?p=4",
                  links: [
                    {
                      title: translator.preciousMetals,
                      url: "/personal?p=5"
                    },
                    {
                      title: translator.foreignExchanges,
                      url: "/personal?p=6"
                    },
                    {
                      title: translator.fractionalStocks,
                      url: "/personal?p=7"
                    },
                    {
                      title: translator.crypto,
                      url: "/personal?p=8"
                    }
                  ]
                },
                {
                  header: translator.manageYourWallet,
                  link: "/personal?p=9"
                }
              ]
            }
          ]
        }
      },
      {
        text: translator.business,
        url: "/business",
        content: {
          id: "businessmenu",
          link: "/business",
          header: "",
          categories: [
            {
              groupId: "business1",
              linkGroups: [
                {
                  header: translator.businessSolutions,
                  link: "/business",
                  links: [
                    {
                      title: translator.embeddedFintechServices,
                      url: "/business?p=1"
                    },
                    {
                      title: translator.financialServicesAPI,
                      url: "/business?p=2"
                    },
                    {
                      title: translator.scoringProtocol,
                      url: "/business?p=3"
                    },
                    {
                      title: translator.microCredit,
                      url: "/business?p=4"
                    },
                    {
                      title: translator.buyNowPayLater,
                      url: "/business?p=5"
                    },
                    {
                      title: translator.investment,
                      url: "/business?p=6"
                    },
                    {
                      title: translator.creditMarketplace,
                      url: "/business?p=7"
                    }
                  ]
                },
                {
                  header: translator.partnersBrands,
                  link: "/business?p=8"
                }
              ]
            }
          ]
        }
      },
      {
        text: translator.company,
        url: "/company",
        content: {
          id: "companymenu",
          link: "/company",
          header: "",
          categories: [
            {
              groupId: "company1",
              linkGroups: [
                {
                  header: translator.aboutUs,
                  link: "/company",
                  links: [
                    {
                      title: translator.ourVision,
                      url: "/company?p=1"
                    },
                    {
                      title: translator.theBoard,
                      url: "/company?p=2"
                    },
                    {
                      title: translator.partnersBrands,
                      url: "/company?p=3"
                    },
                    {
                      title: translator.decentralizedProtocol,
                      url: "/company?p=4"
                    },
                    {
                      title: translator.privacySecurity,
                      url: "/company?p=5"
                    },
                    {
                      title: translator.legal,
                      url: "/company?p=6"
                    }
                  ]
                }
              ]
            },
            {
              groupId: "company2",
              linkGroups: [
                {
                  header: translator.media,
                  link: "/company?p=7"
                },
                // {
                //   header: translator.blog,
                //   link: "/company?p=8"
                // },
                {
                  header: translator.contactUs,
                  link: "/company?p=9"
                }
              ]
            }
          ]
        }
      },
      {
        text: translator.careerCulture,
        isOnRight: true,
        url: "/career",
        content: {
          id: "careermenu",
          link: "/career",
          header: ""
        }
      }
    ]
  }
}
