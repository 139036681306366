export default {
  home: {
    heroTitle: 'Colendi is the Future <br/> of Finance',
    heroDescription:
      'We make simple the complicated by empowering the unempowered.<br/>Providing banking solutions to customers and companies.',
    subscribeButton: 'Subscribe',
    bankingAsAService: {
      title: 'Banking As A Service',
      description: 'Functionality for third parties which enables them to provide branded financial services.',
      button: 'Explore Banking as a Service',
    },
    colendiServices: {
      title: 'We create modern credit and investment on our payment platform',
      header: 'Colendi Services',
      description:
        'We developed a modern credit system utilizing 3.500 data points; custom score cards for users to receive instant and accessible micro-credit.',
    },
    scoring: {
      title: 'Scoring',
      description:
        'An unbiased, dynamic, and learning-<br/>based reliability measure that is<br/>evaluated on top of machine learning algorithms.',
      button: 'Discover Scoring Protocol',
    },
    microCredit: {
      title: 'Micro Credit',
      description:
        'Interest free micro credits for daily <br/>needs; options of paying 30 days later<br/>or in installments.',
      button: 'Check Out Micro Credit',
    },
    buyNowPayLater: {
      title: 'Buy Now Pay Later',
      description:
        'Colendi ables businesses to offer <br/>feasible installment options with low or <br/>no costs. With BNPL, allow your <br/>customers to finalize their purchases <br/>with micro credits and pay at a future date.',
      button: 'More About BNPL',
    },
    invest: {
      title: 'Invest',
      description:
        'Buy, sell, hold, save and prosper.<br/><br/>Simple and comprehensive investment options: foreign exchange, foreign currencies, gold & silver, crypto, and international fractional stocks.',
      button: 'Start Investing',
    },
  },
  personal: {
    pageTitle: 'Colendi - Personal',
    heroText: 'Just in Time Finance',
    gatewayToFinancialFreedom:
      '‘’Gateway to Financial Freedom’’<br/>Colendi serves as a bridge between you and modern financial services.<br/>We are dedicated to empowering every individual by giving them financial inclusion and freedom, enabling them to have a better financial health through Colendi services.',
    slideTexts: {
      topText: 'Only',
      bottomText: 'Specially Customized For You',
      text1: 'What you need',
      text2: 'When you need it',
    },
    miniCards1: [
      {
        title: 'Instant',
        description: 'Credit less than 30 seconds.',
      },
      {
        title: 'Convenient',
        description: 'All services in one app. 24/7 without any paperwork.',
      },
      {
        title: 'No Prejudice',
        description: 'No bank account, no credit history.',
      },
      {
        title: 'Personalized',
        description: 'Personalized credit scoring just for you.',
      },
      {
        title: 'Transparent',
        description: 'No hidden application fees, no penalties, no rollovers.',
      },
      {
        title: 'Inclusive',
        description: '>80% average approval rate.',
      },
    ],
    alternativeScoring: {
      title: 'Alternative Scoring',
      description:
        'Get personally customized credit scores with our new-age scoring algorithm.<br/>We take into account a variety of real data which is transformed into a financial credit score by using machine-learning algorithms. Open a new slate free from your previous financial history.',
    },
    buyNowPayLater: {
      header: 'Buy Now Pay Later',
      subheader: 'With our BNPL feature, pay in the future.',
      title: 'Buy Now Pay Later',
      description:
        'New-age, fast, and smart solutions to meet your financial needs.<br/><br/>Convenient, easy, and safe ways to pay over time with Buy Now Pay Later and installment services. Carefully adjusted and tailored to your needs.',
    },
    instantPaymentOptions: {
      title: 'Instant Payment Options',
      description:
        'Pay daily purchases and needs with no stress.<br/><br/>Gain time at checkout. Finalize your shopping via QR or wallet with ease.',
    },
    invest: {
      title: 'Invest',
      description:
        'Manage your assets in our easy-to-use platform. Build your future with various investment tools such as precious metals, foreign exchanges, stocks & fractional stocks, and crypto.',
    },
    preciousMetals: {
      title: 'Precious Metals',
      description:
        'You can invest in traditional tangible assets such as gold & silver and let us store them for you in a single app.',
    },
    foreignExchanges: {
      title: 'Foreign Exchanges',
      description:
        'Invest in various foreign currencies such as US Dollars, Euros, and Pounds. Execute your buy, sell, trade transactions and secure your assets all in one app.',
    },
    fractionalStocks: {
      title: 'Fractional Stocks',
      description:
        'You don’t have to spend big sums of money to own one full share of a stock. Instead, invest as much as you want and own fractional shares of companies you like.',
    },
    cryptoCurrencies: {
      title: 'Crypto & Currencies',
      description:
        'Buy, sell, send, and swap your favorite cryptocurrencies and store them in your wallet<br/> that is created just for you.',
    },
    manageYourWallet: {
      title: 'Manage Your Wallet',
      description:
        'Colendi financial services platform with its pay, send, spend, track, invest, and trade features helps you to take control over your assets.',
    },
    miniCards2: [
      {
        title: 'Pay',
        description: 'Pay your bills and daily purchases with no stress.',
      },
      {
        title: 'Send & Receive',
        description: 'Send and receive money wherever you are.',
      },
      {
        title: 'Spend',
        description: 'Spend on your future, on experiences, on your loved ones.',
      },
      {
        title: 'Track',
        description: 'Keep track of your personal finances, instant limits and payments.',
      },
      {
        title: 'Invest',
        description:
          'Build your own future with various investment tools. Manage your assets in our easy-to-use platform.',
      },
      {
        title: 'Trade',
        description: 'Become your own financier and trade with ease of your own accord.',
      },
    ],
  },
  business: {
    pageTitle: 'Colendi - Business',
    heroText: 'Business Solutions',
    heroDesc:
      'Grow your business and stay competitive with our agile solutions. Colendi is here for a smooth transition into the fintech world.',
    circleTexts: [
      '<strong><strong>Identity &<br/>Credit Score</strong></strong>',
      '<strong><strong>Microcredit<br/>& Instant Limits</strong></strong>',
      '<strong><strong>Bill<br/>Payments</strong></strong>',
      '<strong>BNPL<br/>(Buy Now Pay Later)</strong>',
      '<strong>Money Transfers</strong><br/><light>Remittance, EFT 24/7</light>',
      '<strong>Prepaid Visa<br/>& Master Card</strong>',
      '<strong>E-Money Account<br/>& Virtual Card</strong>',
      '<strong>Online & In-store<br/>QR Payments</strong>',
      '<strong>Micro Invest<br/>Stocks & Crypto</strong>',
      '<strong>Chrome<br/>Extension</strong>',
      '<strong>Gaming & E-sport<br/>Digital Services</strong>',
      '<strong>Credit Card<br/>Installment Features</strong><br/>Flexible Payments',
      '<strong>NFT Marketplace</strong>',
      '<strong>Mobile point<br/>of Sale</strong>',
      '<strong>Insurance</br>Digital Insurance</br>Services</strong>',
      '<strong>Travel</strong><br/>Fly Now, Pay Later<br/>Stay Now, Pay Later',
      '<strong>Academy</strong>',
    ],
    embeddedFintechServices: {
      title: 'Embedded Fintech Services',
      description:
        'We collect traditional banking services, improve them with our cutting edge data technology to make it accessible and affordable to everyone regardless of their financial backgrounds.',
    },
    financialServicesAPI: {
      title: 'Financial Services API',
      description:
        'Colendi’s API provides customized financial solutions suitable to your users’ needs. We equip you with clear API services that are easily integrable into your systems.<br/><br/>Ultimately, offering added value services for better customer satisfaction and optimized business operations.',
    },
    scoringProtocol: {
      title: 'Scoring Protocol',
      description:
        'Scoring protocol allows you to have an alternative, unbiased reliability measure for your current and future customers. With our advanced technology, it is possible to perform fraud control and credit scoring at the same time.<br/><br/>Our scoring and fraud detection features are evaluated on top of machine learning algorithms, both are supervised and unsupervised.',
    },
    creditAsAService: {
      title: 'Credit As A Service',
      description:
        'Your own wallet, your own customers, and your own business with our credit solution. Via our flexible and easy-to-use credit payment solutions create new user experiences.',
    },
    microCredit: {
      title: 'Micro Credit',
      description:
        'For lucid and accessible finance, we have created modern credit. Colendi offers short-term, interest free loans with Buy Now Pay Later, delay, defer, and installment options.<br/><br/>Allow your customers to make purchases without the stress of a sudden financial burden, let them pay 30 days later, or in installments. Offer personally adjusted interest free micro credits to your customers for a better purchasing experience.',
    },
    buyNowPayLater: {
      title: 'Buy Now Pay Later',
      description:
        'Best way to pay over time. Give your users more time to pay.<br/><br/>A cutting-edge, rapid, and smart solutions to  your users’ instant needs. With a convenient way to pay, your customers can make purchases and pay at a future date without interest.',
    },
    beYourOwnFinancier: {
      title: 'Be Your Own Financier',
      description:
        'Allow your users to invest and save with our investment options. Easy-to-use, agile, and comprehensive investment opportunities for your users.',
    },
    investInYourBusiness: {
      title: 'Invest In Your Business',
      description:
        'Fast and easy investment opportunities for your users.<br/><br/> Through the wallets we have developed, users can invest & micro-invest in various commodities, trade them 24/7, and get up-to-date market info in a single platform.',
    },
    investment: {
      title: 'Investment',
      description:
        'All commodities, precious metals, foreign currencies, stocks & fractional stocks are accessible to your users through our various banking partners.<br/><br/>On top of the traditional investment <br/>options, offer your users a wide range of cryptocurrencies with a reliable custody service.',
    },
    creditMarketplace: {
      title: 'Credit Marketplace',
      description:
        'Fully digital credit process. Colendi marketplace, with its network of 1.000+ merchants, offers instant credit with optimized rates allowing you to acquire a network of various business partners for you to expand your business operations in a more efficient way.',
    },
    partnersBrands: {
      title: 'Partners & Brands',
      description: 'Platform Partners',
    },
  },
  company: {
    pageTitle: 'Colendi - Company',
    heroText: 'About Us',
    heroDesc:
      'Colendi was founded to empower every individual regardless of gender, background, financial position, banked or unbanked to give them financial freedom and financial inclusion through simple, easy-to-use products.<br/><br/>Colendi provides embedded fintech services that democratize banking services for consumers, merchants, financial and non-financial institutions to overcome the limitations associated with the legacy banking.',
    littleCards1: [
      {
        title: 'Strong Team',
        description:
          'Our management team has extensive experience in finance, banking, fintech and payment solutions. Our engineers have DeFi and ReFi to deliver robust, fast and secure solutions.',
      },
      {
        title: 'JV Partners',
        description:
          'Our JV partners become fintech platforms instantly at zero cost.<br/>“Every Company will be fintech Company”.',
      },
      {
        title: 'Business Partners',
        description: 'Has already secured business partners with access to over 100M+ individuals.',
      },
      {
        title: 'Traction',
        description: 'Currently Colendi has 7.000.000+ users and delivered 5.000.000+ credit transactions.',
      },
      {
        title: 'Marketplace',
        description:
          'Colendi works with JV Partners to utilize embedded fintech solutions through its marketplace to provide invest, credit and payment solutions.',
      },
      {
        title: 'Our Philosophy',
        description:
          'We empower the unempowered by providing a simple product to individuals regardless of their history, banked or unbanked, to sell, buy, hold, borrow, and therefore save and prosper.',
      },
    ],
    boardCards: [
      {
        name: 'Sir David Walker',
        title: 'Board Member',
        extraText: 'British banker and former chairman of Barclays.'
      },
      {
        name: 'Bülent Tekmen',
        title: 'Co-Founder - Board Member',
      },
      {
        name: 'Christian Noyer',
        title: 'Board Member',
        extraText: 'French economist, Honorary Governor of the Banque de France.'
      },
      {
        name: 'Deniz Devrim Cengiz',
        title: 'Board Member',
      },
      {
        name: 'Mihriban Ersin Tekmen',
        title: 'Co-Founder - Board Member',
      },
      {
        name: 'Ian Hannam',
        title: 'Board Member',
        extraText: 'British investment banker JP Morgan and founder of Hannam & Partners.'
      },
      {
        name: 'İnanç Balcı',
        title: 'Board Member',
        extraText: 'Founder of Lazada (Acquired by Alibaba) , Founder of Crestone Venture Capital'
      },
      {
        name: 'Prof Philip Bond',
        title: 'Board Member',
        extraText: "British mathematician, currently a visiting Professor at the University of Bristol, one of the world's leading academics in cryptography."
      },
      {
        name: 'Bulut Arukel',
        title: 'Board Member',
        extraText: "Experienced finance leader and Endeavor mentor.\n" +
          "Member of TUSİAD\n" +
          "Founder CEO of MoneyPay Tech."
      },
    ],
    partnersBrands: {
      title: 'Partners & Brands',
      description: 'Platform Partners',
    },
    decentralizedProtocol: {
      title: 'Decentralized Protocol',
      description: 'Global & Democratic Digital Credit Scoring Beyond Banks',
    },
    littleCards2: [
      {
        title: 'Decentralized Scoring Protocol',
        description:
          'Distributed data is evaluated in an isolated manner via Colendi Decentralized Scoring Engine and Colendi Protocol through data integrated blockchain nodes. No data is collected from a node or shared in the network. Final scoring results are brought together to create global Colendi Financial Passport.',
      },
      {
        title: 'Bank-free Risk Assessment',
        description:
          'Real-life data, smartphone data, shopping, merchant transactions, telco billings, financial history, social media and many more is scored independently and securely in closed data nodes to create real and covering digital credibility image of a person, beyond limited financial history.',
      },
      {
        title: 'Global Credibility Identity',
        description:
          'Your unique and mobile credibility identity. Colendi Identity is global, and depends on no country or central bureau. Your id is yours only, and your score is mobile and valid globally thanks to the Colendi blockchain network. Global credibility ID is created securely via cutting-edge verification mechanism.',
      },
      {
        title: 'ML Based Credibility',
        description:
          'Intelligence is the core of Colendi Decentralized Scoring. Each node’s data is evaluated continuously on top of Machine Learning algorithms. Both scoring and fraud detection is dynamic and learning-based, assuring that overall financial passport always indicates the real credibility of a user.',
      },
      {
        title: 'Enhanced Fraud Protection',
        description:
          'Decentralized Scoring Engine covers fraud mechanisms as well as scoring. Both real time and historical data are processed via intelligent algorithms in each node. Therefore, unreliable users, as well as fraud operations, are detected instantly, enhancing overall security and reliability of the protocol.',
      },
    ],
    privacySecurity: {
      title: 'Privacy & Security',
      description: 'How We Handle Data',
    },
    littleCards3: [
      {
        title: 'Given Data',
        description:
          'As you sign up, you provide demographic data such as name, email, gsm number, etc., and in some cases additional information to verify your identity in suspicious events such as fraudulent transactions, money laundering etc.',
      },
      {
        title: 'Collected Data',
        description:
          'With regard to our services, we may collect the following data about you;<br/>● Transaction details such as amount, date etc.,<br/>● Technical information such as IP address, login information, browser information, and operating system details,<br/>● Information of your visit related to URL stream, duration of your visit, application usage, etc.',
      },
      {
        title: 'Shared Data',
        description:
          'We are legally allowed to work (just work, not transfer to anywhere) on data stored on our partners’ servers. If you allowed your data to be used by partners, we may work on that data to give you a better service. We work on the data stored on our partners’ servers to offer you better and more convenient services. However, even though we work on your data, we don’t share it with 3rd parties.',
      },
      {
        title: 'Sensitive Data',
        description:
          'Fully aligned with regulatory laws, we process a limited amount of sensitive data.<br/>The sensitive data is protected by Colendi via;<br/>● Encrypting all the data between the internet and our services, <br/>● Updating our software and operating systems in a timely manner, <br/>● Using a backup protocol to keep your data safe, <br/>● Training our employees for both regulatory and personal security needs.',
      },
    ],
    legal: 'Legal',
    littleCards4: [
      {
        description:
          'Colendi brings together the authorized and licensed financial institutions (such as payment institutions and e-money institutions, banks, financing companies, investment firms, crypto exchanges, portfolio management companies, insurance companies) and platform users as an integrator.',
      },
      {
        description:
          'In addition, Colendi provides a unique and alternative scoring service to users within the scope of the Law on Personal Data Protection (LPDP), within the permissions and consents <br/>it receives from users and platforms.',
      },
      {
        description:
          'Colendi encourages the cooperated institutions to act per the legislation and regulations to which they are affiliated and conducts maximum efforts to protect the rights of users at all stages of the customer experience.',
      },
    ],
    blog: 'Blog',
    media: {
      title: 'Media',
      description: 'Discover Colendi in media and access our media kit',
    },
    contactUs: {
      title: 'Contact us',
      description: 'You can reach us anytime via <strong>info@colendi.com</strong>',
      agreementText: 'By clicking Get Started, you agree to our',
      agreementLink: 'Privacy Policy',
    },
    cardVision: {
      title: 'Our Vision',
      description:
        'Get personally customized credit scores with our new-age scoring algorithm.<br/><br/>We take into account a variety of real data which is transformed into a financial credit score by using machine-learning algorithms. Open a new slate free from your previous financial history.',
    },
  },
  career: {
    pageTitle: 'Colendi - Career & Culture',
    heroText: 'Career & Culture',
    heroDesc: 'Don’t waste more time thinking what you meant to do,</br>be what you meant to be with Colendi.',
    typicalColendian: 'What does a typical Colendian look like?',
    colendianStyle: {
      title: 'Colendian Style of Working',
      description: 'Shared Vision, Shared Culture',
    },
    thinkOutsideBox: {
      title: 'Thinking Outside of the Box',
      description:
        'In our field, critical thinking and creativity can take us a long way. That is why we try to surround ourselves with individuals that are capable of seeing things from different perspectives.',
    },
    flexible: {
      title: 'Flexible in a Powerful Way',
      description:
        "We don't follow certain discipline rules, but we make it a discipline. We believe that flexibility brings out the power in us.",
    },
    passion: {
      title: 'Passion is Our Joy',
      description:
        'At Colendi, we are all passionate individuals who mirror our passion to each other. This way,we incentivize our teammates to find their own passion while they are working alongside their teammates.',
    },
    sharing: {
      title: 'Sharing is Caring',
      description:
        'If someone in our team has a know-how, they teach others how to know. We believe that we, as individuals, only get better when we share our knowledge and experience with our teammates and accept it as one of our core strengths. We are not afraid of making mistakes, we know Colendians will help us along the way.',
    },
    benefits: 'Benefits of Being a Colendian',
    life: 'Life at Colendi',
    nutshell: 'Colendi in a Nutshell',
    colendi: {
      word: 'Colendi (noun)',
      spelling: "co •len •di | \\ ko -'len -'di",
      mean1: '1. Which is to be cultivated',
      mean2: '2. Which is to be protected',
      mean3: '3. Which is the future of finance',
    },
    colendian: {
      word: 'Colendian (noun)',
      spelling: "co -len •di •an |  ko -len - 'di - an,",
      mean1: '1. An individual whose roots are connected to Colendi',
      mean2: '2. A cultured, refined and educated individual',
    },
    openPosition: {
      join: 'Join Us',
      text: 'We work for a shared cause. We want to transform the financial<br/>world into a better and a fairer place. With this vision, Colendi<br/>stands for building a culture of lifetime learning, constantly evolving<br/>and developing classless, meritocratic and always with a sense of<br/>humor work environment.<br/>At Colendi, we work at the crossroads between finance, technology,<br/>retail, and fund management. We believe in the right of every<br/>individual to choose and believe that education creates<br/>understanding. If you share our dream and want to change the<br/>financial world for the better, we are on a path where we need<br/>like-minded people.',
      apply: 'Apply',
      applyNow: 'Apply Now',
      tellAbout: "Why don't you tell us a little about yourself?",
      more: 'Show More',
    },
  },
  media: {
    pageTitle: 'Colendi - Media',
    media: 'Media',
    newsroom: 'Newsroom',
    pressRelease: 'Press Release',
    events: 'Events',
    mediaKit: 'Media Kit',
    emailMedia: 'Please send us an email for your media inquiries.',
    getInTouch: 'Get in Touch!',
  },
  blog: {
    blogsPageTitle: 'Colendi - Blogs',
    blogPageTitle: 'Colendi - _blog',
    blogs: 'Blogs',
    share: 'Share',
    allStories: 'All stories',
  },
  cookies: {
    cookie1: {
      text: "We use essential cookies to make our platform work. These cookies are strictly necessary to enable core functionality such assecurity, network management, your cookie preferences and accessibility. Without them you wouldn't be able to use basicservices. You may disable these by changing your browser settings, but this may affect how the Websites function.",
      title: 'Essential Cookies',
    },
    cookie2: {
      text: 'These cookies are used to enhance the performance and functionality of our platform but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the platform as we would not be able to remember that you had logged in previously.',
      title: 'Performance and Functionality Cookies',
    },
    cookie3: {
      text: 'These account-based marketing cookies enable us to identify future prospects and personalize sales and marketing interactions with them.',
      title: 'Marketing Cookies',
    },
    cookie4: {
      text: 'These cookies collect information that is used to help us understand how our platform is being used or how effective our marketing campaigns are, or to help us customize our platform for you.',
      title: 'Analytics and Customization Cookies',
    },
    cookie5: {
      text: 'Some cookies that have been set on our platform are not set on a first party basis by Colendi. The Websites can be embedded with content from third parties to serve advertising. These third party service providers may set their own cookies on your web browser. Third party service providers control many of the performance and functionality, advertising, marketing and analytics cookies described above. We do not control the use of these third party cookies as cookies can only be accessed by the third party that originally set them.',
      title: 'Third Party Cookies',
    },
    reason: {
      start:
        'We use cookies to help ensure that our website is able to function properly. These cookies are necessary and so are set automatically. At the same time, some cookies are used as well to make your visit more personal, improve our website based on how you use it and support our marketing activities. These cookies are optional.</br></br>You can accept all cookies by selecting “Accept All” or choose to manage which by “Manage Cookie Settings”</br></br>To learn more about cookies, please visit our ',
      link: 'Cookie Policy',
    },
    acceptAll: 'Accept All',
    acceptSelected: 'Accept Selected',
    close: 'Close cookie settings',
    open: 'Open cookie settings',
  },
  sitemap: {
    personal: 'Personal',
    business: 'Business',
    company: 'Company',
    careerCulture: 'Career & Culture',
    justInTimeFinance: 'Just in Time Finance',
    invest: 'Invest',
    manageYourWallet: 'Manage Your Wallet',
    businessSolutions: 'Business Solutions',
    partnersBrands: 'Partners & Brands',
    aboutUs: 'About Us',
    media: 'Media',
    blog: 'Blog',
    contactUs: 'Contact Us',
    alternativeScoring: 'Alternative Scoring',
    buyNowPayLater: 'Buy Now Pay Later',
    instantPaymentOptions: 'Instant Payment Options',
    preciousMetals: 'Precious Metals',
    foreignExchanges: 'Foreign Exchanges',
    fractionalStocks: 'Fractional Stocks',
    crypto: 'Crypto',
    embeddedFintechServices: 'Embedded Fintech Services',
    financialServicesAPI: 'Financial Services API',
    scoringProtocol: 'Scoring Protocol',
    microCredit: 'Micro Credit',
    investment: 'Investment',
    creditMarketplace: 'Credit Marketplace',
    ourVision: 'Our Vision',
    theBoard: 'The Board',
    decentralizedProtocol: 'Decentralized Protocol',
    privacySecurity: 'Privacy & Security',
    legal: 'Legal',
  },
  job:{
    agreementText: 'By clicking Submit Application, you agree to our',
    agreementLink: 'Privacy Policy',
    linkedIn: 'Link to LinkedIn profile (optional)',
    summary: 'Summary',
    attach: 'Click to Attach Document',
    submit: 'Submit Application',
    applyRole: 'Apply For This Role',
    personal: 'Personal Details',
    experience: 'Experience',
  },
  common: {
    readMore: 'Read more',
    showMore: 'Show more',
    loadMore: 'Load More',
    getStarted: 'Get Started',
    fullName: 'Full name',
    email: 'E-mail',
    phone: 'Phone number',
    howCanWeHelp: 'How Can We Help?',
    invalidName: 'Invalid name',
    invalidLinkedin: 'Invalid linkedin url',
    invalidEmail: 'Invalid e-mail',
    invalidPhone: 'Invalid phone',
    successContact: 'Thank you for contacting us!',
    successApply: 'Thank you for applying!',
    contactReplyMessage: 'Our team will be in touch with you shortly',
    close: 'Close',
    download: 'Download',
    subscribed: 'Subscribed',
    subscribeColendi: 'Subscribe to Colendi',
    subscribe: 'Subscribe',
    enterMail: 'Enter your email to stay connected with us!',
    checkMail: 'Please check your e-mail to activate your account.',
    colendiYear: '©2025 Colendi',
    bottomDisclaimer:
      'The contents of this website are believed to be reliable, but the accuracy or completeness of any information contained herein is not guaranteed and members of Colendi group shall not in any way be liable for any delay in keeping such information current. Members of Colendi group specifically disclaim all warranties, express or implied, except for those derived from its executed agreements. Although Colendi group believes that the information provided on these pages is accurate, it provides no warranty with respect thereto. The information may not be suitable for all persons; it should not be relied upon in connection with a particular customer’s transaction; and,  is not intended to be, nor should be construed as, an offer, recommendation, or solicitation. Colendi group does not provide any advice,  and only accepts customers who have read and understand the relevant disclosure documents. The information on this website is not intended for persons who reside in jurisdictions where providing such information would violate the laws or regulations of such jurisdiction.',

  },
}
