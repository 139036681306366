import React, { CSSProperties } from 'react'
import {Menupanel} from '@src/components/menupanel'
import styles from '@src/styles/navbar.module.scss'
import AppContext from '../../AppContext'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const MenuHeader = (props: { content: any; text: string, isOnRight:boolean}) => {
  const appContext=React.useContext(AppContext)

  function checkCurrent(): boolean {
    return appContext!.navbarMenuId === props.content.id
  }

  const menuSelected = (e:any) => {
    {/* @ts-ignore */}
    window.gtag('event',`web_click_${(props.content.link).replace('/','')}`)
    //mobile size web size
    if (window.innerWidth<800) {
      return
    }
    appContext!.setNavbarMenuId('')
    e.preventDefault()
    e.stopPropagation()
    if (props.content.link!==undefined) {
      window.location.href = props.content.link
    }
  }

  const menuEnter = () => {
    if (appContext!.navbarMenuId !== props.content.id) {
      // This one is selected, should open
      appContext!.setNavbarMenuId(props.content.id)
    }
  }

  const menuExit = () => {
    if (appContext!.navbarMenuId === props.content.id) {
      // Already selected one was open
      appContext!.setNavbarMenuId('')
    }
  }

  const styl:CSSProperties={
  }

  if (props.isOnRight){
    styl.color='#528FCE'
    styl.borderColor='#528FCE'
  }

  return (
    <div
      id={props.content.id}
      role='button'
      key={'n-'+props.text.toLowerCase().replaceAll(' ','-').substring(0,20)}
      className={classNames(
        checkCurrent() ? styles.panelTitleSelected : (styles.panelTitleUnselected),
        ' ',styles.panelTitle
      )}
      style={styl}
      onMouseOver={menuEnter}
      onMouseLeave={menuExit}
      onClick={menuSelected}
    >
      {`${props.text}`}
      <Menupanel options={props.content} isOnRight={props.isOnRight} />
    </div>
  )
}
