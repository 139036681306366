import Link from 'next/link'
import { Collapse } from '@src/components/collapse'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import styles from '@src/styles/footer.module.scss'
import { SocialMediaLinks } from '@src/pages/_app'
import { useEffect, useRef } from 'react'
import useOnScreen from '@src/hooks/isOnScreen'
import useSitemap from '@src/sitemap'
import useTranslation from '@src/hooks/i18n'

interface HomePageFooterProps {
  links?: SocialMediaLinks;
  setFooterIsInViewport: (val: boolean) => void
}

const HomepageFooter: React.FC<HomePageFooterProps> = ({ links, setFooterIsInViewport }) => {
  const commonTranslator = useTranslation().common
  const sitemapData = useSitemap().menus
  const footerRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const isVisible = useOnScreen(footerRef)

  useEffect(() => {
    setFooterIsInViewport(isVisible)
  }, [isVisible])

  // @ts-ignore
  library.add(fab)

  // const goTo = (link: string) => {
  //   void Router.push(link)
  // }
  function goTo(e: any, link: string) {
    if (link !== '') {
      e.preventDefault()
      e.stopPropagation()
      window.open(link, '_blank')?.focus();
      // window.location.href = link
    }
    return false
  }

  // @ts-ignore
  return (
    <>
      <div ref={footerRef} className={styles.footerBody}>
        <div className="flex flex-col lg:flex-row">
          <div className="flex m-auto lg:m-0 lg:ml-8 flex-col justify-center lg:justify-start pt-8 items-center w-1/2 lg:w-1/6 basis-[min-content]">
            <img src="/colendi_logo_white.svg" width="200vw" height="55vw" alt="colendi" />
            <span className="pt-4 text-white">info@colendi.com</span>
            {links &&
              <div className="flex items-center lg:flex-col">
                <div className="flex pt-4 w-auto lg:w-[min(15vw,200px)]">
                  <div role="button" onClick={(e) => {
                    {/*@ts-ignore */}
                    window.gtag('event','web_click_instagram')
                    goTo(e, links.instagramLink)
                  }} className={`${styles.footerIconContainer} z-10 w-[10vw] mx-[2vw] lg:mx-0 lg:w-[5vw]`}>
                    <img className={styles.footerIcon} src="/instagram-icon-white.png" alt="instagram" />
                    <img className={styles.footerIconHover} src="/instagram-icon-hovered.png" alt="instagram" />
                  </div>
                  <div role="button" onClick={(e) => {
                    {/*@ts-ignore */}
                    window.gtag('event','web_click_linkedin')
                    goTo(e, links.linkedInLink)
                  }} className={`${styles.footerIconContainer} z-10 w-[10vw] mx-[2vw] lg:mx-0 lg:w-[5vw]`}>
                    <img className={styles.footerIcon} src="/linkedin-icon-white.png" alt="linkedin" />
                    <img className={styles.footerIconHover} src="/linkedin-icon-hovered.png" alt="linkedin" />
                  </div>
                  <div role="button" onClick={(e) => {
                    {/*@ts-ignore */}
                    window.gtag('event','web_click_twitter')
                    goTo(e, links.twitterLink)
                  }} className={`${styles.footerIconContainer} z-10 w-[10vw] mx-[2vw] lg:mx-0 lg:w-[5vw]`}>
                    <img className={styles.footerIcon} src="/twitter-icon-white.png" alt="twitter" />
                    <img className={styles.footerIconHover} src="/twitter-icon-hovered.png" alt="twitter" />
                  </div>
                </div>
                {/* <div className="flex pt-4 lg:pt-0 w-auto lg:w-[min(10vw,150px)]"> */}
                <div className="flex pt-4 lg:pt-0 w-auto lg:w-[min(15vw,200px)]">
                  <div role="button" onClick={(e) => {
                    {/*@ts-ignore */}
                    window.gtag('event','web_click_facebook')
                    goTo(e, links.facebookLink)
                  }} className={`${styles.footerIconContainer} z-10 w-[10vw] mx-[2vw] lg:mx-0 lg:w-[5vw]`}>
                    <img className={styles.footerIcon} src="/facebook-icon-white.png" alt="facebook" />
                    <img className={styles.footerIconHover} src="/facebook-icon-hovered.png" alt="facebook" />
                  </div>
                  <div role="button" onClick={(e) => {
                    {/*@ts-ignore */}
                    window.gtag('event','web_click_telegram')
                    goTo(e, links.telegramLink)
                  }}
                    className={`${styles.footerIconContainer} z-10 w-[10vw] mx-[2vw] lg:mx-0 lg:w-[5vw]`}>
                    <img className={styles.footerIcon} src="/telegram-icon-white.png" alt="telegram" />
                    <img className={styles.footerIconHover} src="/telegram-icon-hovered.png" alt="telegram" />
                  </div>
                  <div role="button" onClick={(e) => {
                    {/*@ts-ignore */}
                    window.gtag('event','web_click_youtube')
                    goTo(e, links.youtubeLink)
                  }} className={`${styles.footerIconContainer} z-10 w-[10vw] mx-[2vw] lg:mx-0 lg:w-[5vw]`}>
                    <img className={styles.footerIcon} src="/youtube-icon-white.png" alt="youtube" />
                    <img className={styles.footerIconHover} src="/youtube-icon-hovered.png" alt="youtube" />
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="flex-2 pt-8 md:pt-0 md:pl-8">
            <div>
              <div className={styles.footerLinksContainer}>
                {
                  sitemapData.map((menu) => {
                    const identifier = menu.text.toLowerCase()
                    const classNames = 'flex-1 flex flex-row'
                    return (
                      <div key={`fc-${identifier}`} className="flex-1 px-1 flex flex-col" style={{ marginLeft: '0px' }}>
                        <Link href={menu.url}><a onClick={()=>{{/*@ts-ignore */}
                    window.gtag('event',`web_click_${(menu.url).replace('/','')}`)}} className="hover:underline" style={{
                          fontSize: '18px', fontWeight: '700', color: '#ffffff', marginBottom: '0.5rem'
                        }}>{menu.text}</a>
                        </Link>
                        {menu.content.categories &&
                          <div key={`fr-${identifier}`} className={classNames}>
                            {menu.content.categories.map((linkCategory) => {
                              return (
                                <div key={`fg-${linkCategory.groupId}`} className="flex flex-col py-2" style={{ paddingRight: '5vw' }}>
                                  {linkCategory.linkGroups.map((linkGroup) => {

                                    return (
                                      <div key={`fh-${linkGroup.header}`}>
                                        <div className="text-white" style={{
                                          fontSize: '16px',
                                          fontWeight: '600',
                                          paddingBottom: '0.5rem',
                                          whiteSpace: 'nowrap'
                                        }}>
                                          {linkGroup.link === undefined &&
                                            <p className="hover:underline">{linkGroup.header}</p>
                                          }
                                          {linkGroup.link !== undefined &&
                                            <a onClick={()=>{{/*@ts-ignore */}
                                            window.gtag('event',`web_click_${(linkGroup.header).toLowerCase().replace(' ','_')}`)}} href={linkGroup.link!} className="hover:underline">{linkGroup.header}</a>
                                          }
                                        </div>
                                        {
                                          linkGroup.links && linkGroup.links.map((link) => {
                                            return <div key={`fl-${link.title.toLocaleLowerCase().replaceAll(' ', '-')}`}
                                              className={styles.linkText}>
                                              <div role="button" onClick={(e) => {
                                                {/*@ts-ignore */}
                                            window.gtag('event',`web_click_${(link.title).toLowerCase().replace(' ','_')}`)
                                                goTo(e, link.url)
                                              }}>{link.title}</div>
                                            </div>
                                          })
                                        }
                                      </div>
                                    )
                                  })
                                  }
                                </div>
                              )
                            }
                            )
                            }
                          </div>
                        }
                      </div>
                    )
                  })
                }
              </div>
              <div className="block lg:hidden">
                <Collapse sitemap={sitemapData} />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className={styles.disclaimerBody} style={{ fontSize: '13px' }}>
        <div className="max-w-[1170px]">
          <p>{commonTranslator.colendiYear}</p>
          <p className="py-[20px]">
            {commonTranslator.bottomDisclaimer}
          </p>
        </div>
      </div>
    </>
  )
}

export default HomepageFooter
