import en from '@src/lang/en'
import tr from '@src/lang/tr'
import { useEffect, useState } from 'react'

export default function useTranslation(): any {
  const [i18n, setI18n] = useState<any>(en)

  useEffect(() => {
    let userLang = localStorage.getItem('lang')
    if (!userLang) {
      userLang = navigator.language
    }
    if (userLang === 'tr' || userLang === 'tr-TR') {
      setI18n(tr)
    } else {
      setI18n(en)
    }
  }, [])

  return i18n
}

